import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';
import {
  CardValidation,
  PasswordValidation,
} from '../../../../../common/validation/validation';
import { ValidationConstant } from '../../../../../common/validation/validation.constant';
import { Validator } from '../../../../../common/validation/validator';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { StorageService } from 'src/app/common/services/storage.service';
import { PaymentInformationService } from '../../services/payment-information.service';
import { Subscription } from 'rxjs';
import { MessageSetting } from 'src/app/common/constants/message-setting.constant';
import { Exception } from 'src/app/common/exceptions/exception';
import { CustomDropdownComponent } from '../custom-dropdown/custom-dropdown.component';
import { DecimalPipe } from '@angular/common';
import { LaunchDarklyService } from '../../services/launch-darkly.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
  providers: [DecimalPipe]
})
export class PaymentInformationComponent implements OnInit,AfterViewInit {
  @Output() newTitleEvent = new EventEmitter<boolean>();
  @ViewChildren('form') forms: QueryList<any>;
  @ViewChild('customDropdown', { static: true }) customDropdown: ElementRef;

  modalRef: BsModalRef;
  paymentInfoForm: any;
  addACHForm: any;
  addCardForm: any;
  subscription: Subscription;

  submitted = false;
  paymentInfoFormErrors: any = {};
  addACHFormErrors: any = {};
  addCardErrors: any = {};

  securityCodeLength: number = 3;
  postalCodeLength: any;
  postalCodeMinLength: any = 5;
  postalCodeMask: any = '00000-0000';
  readonlyBankField: boolean = false;
  validator: Validator;
  public show_tab = '';
  showPaychoice: boolean = false;
  showPaychoiceContainer: boolean = true;
  clickCardTime = 0;
  clickAchTime = 0;

  lookupStateList = [];
  lookupCountryList: any;
  settings: any;
  fee: any;
  feeMessage: string;
  totalAmount: any;
  merchantName: string;
  isCalculated: boolean = false;
  proccedPaymentStatus: boolean = false;
  cardCalledInputs: string[] = [];
  cardImageName: string = '../../../../assets/images/icon-all-card.png';
  defaultAllCards = true;
  singleCard: boolean = false;
  contactInfo: any;
  cardDetails: any;
  searchValue;
  isConfirmDisabled = true;
  paymentTypeChoice = {
    ACH: false,
    Debit: false,
    Credit: false,
    CARD: false,
  };
  showError: boolean = false;
  globalCardType = 'CREDIT';
  password = '';
  unmaskedPassword = '';
  pattern = '●';
  delay = 2000;
  isTimeoutRunning = false;
  paymentSubmitted = false

  allowTransctioList = {
    CreditCard: true,
    DebitCard: true,
    ACH: true,
  };
  applicableToList = {
    CreditCard: false,
    DebitCard: false,
    ACH: false,
    CreditCardMsg: 'No Technology Fees Set',
    DebitCardMsg: 'No Technology Fees Set',
    ACHMsg: 'No Technology Fees Set',
  };
  showPassword = false;
  timeout: any = null;
  selectedBoxSection = false;
  selectedCountry: any;

  @ViewChild('bankName') elRefbankName: ElementRef;
  @ViewChild('inputRoutingLabel') elRefinputRoutingLabel: ElementRef;
  @ViewChild('postalCodelabel') elRefpostalCodelabel: ElementRef;

  config = {
    Amount: {
      required: { name: ValidationConstant.paymentInfo.amount.name },
      pattern: { name: ValidationConstant.paymentInfo.amount.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.amount.name,
        max: ValidationConstant.paymentInfo.amount.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.amount.name,
        min: ValidationConstant.paymentInfo.amount.minLength.toString(),
      },
    },
    accountHolderName: {
      required: {
        name: ValidationConstant.paymentInfo.accountHolderName.requireName,
      },
      pattern: { name: ValidationConstant.paymentInfo.accountHolderName.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.accountHolderName.name,
        max: ValidationConstant.paymentInfo.accountHolderName.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.accountHolderName.name,
        min: ValidationConstant.paymentInfo.accountHolderName.minLength.toString(),
      },
    },
    routingNumber: {
      required: {
        name: ValidationConstant.paymentInfo.routingNumber.requireName,
      },
      pattern: { name: ValidationConstant.paymentInfo.routingNumber.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.routingNumber.name,
        max: ValidationConstant.paymentInfo.routingNumber.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.routingNumber.name,
        min: ValidationConstant.paymentInfo.routingNumber.minLength.toString(),
      },
    },
    accountNumber: {
      required: {
        name: ValidationConstant.paymentInfo.accountNumber.requireName,
      },
      pattern: { name: ValidationConstant.paymentInfo.accountNumber.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.accountNumber.name,
        max: ValidationConstant.paymentInfo.accountNumber.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.accountNumber.name,
        min: ValidationConstant.paymentInfo.accountNumber.minLength.toString(),
      },
    },
    verifyAccountNumber: {
      required: {
        name: ValidationConstant.paymentInfo.verifyAccountNumber.name,
      },
      pattern: {
        name: ValidationConstant.paymentInfo.verifyAccountNumber.name,
      },
      maxlength: {
        name: ValidationConstant.paymentInfo.verifyAccountNumber.name,
        max: ValidationConstant.paymentInfo.verifyAccountNumber.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.verifyAccountNumber.name,
        min: ValidationConstant.paymentInfo.verifyAccountNumber.minLength.toString(),
      },
      matchAccountNumber: { name: 'Verify bank account' },
      blankVerifyAccountNumber: { name: 'bank account number' },
    },
    accountType: {
      required: { name: ValidationConstant.paymentInfo.accountType.name },
      pattern: { name: ValidationConstant.paymentInfo.accountType.name },
    },
    bankName: {
      required: { name: ValidationConstant.paymentInfo.bankName.name },
      pattern: { name: ValidationConstant.paymentInfo.bankName.name },
    },
    NameOnCard: {
      required: { name: ValidationConstant.paymentInfo.NameOnCard.requireName },
      pattern: { name: ValidationConstant.paymentInfo.NameOnCard.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.NameOnCard.name,
        max: ValidationConstant.paymentInfo.NameOnCard.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.NameOnCard.name,
        min: ValidationConstant.paymentInfo.NameOnCard.minLength.toString(),
      },
    },
    CardNumber: {
      required: { name: ValidationConstant.paymentInfo.CardNumber.requireName },
      pattern: { name: ValidationConstant.paymentInfo.CardNumber.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.CardNumber.name,
        max: ValidationConstant.paymentInfo.CardNumber.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.CardNumber.name,
        min: ValidationConstant.paymentInfo.CardNumber.minLength.toString(),
      },
    },
    cardExpiry: {
      required: { name: ValidationConstant.paymentInfo.cardExpiry.requireName },
      pattern: { name: ValidationConstant.paymentInfo.cardExpiry.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.cardExpiry.name,
        max: ValidationConstant.paymentInfo.cardExpiry.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.cardExpiry.name,
        min: ValidationConstant.paymentInfo.cardExpiry.minLength.toString(),
      },
      expiryDate: { name: 'expiration date' },
      expiryDateInvalid: { name: 'expiration date' },
    },
    SecurityCode: {
      required: {
        name: ValidationConstant.paymentInfo.SecurityCode.requireName,
      },
      pattern: { name: ValidationConstant.paymentInfo.SecurityCode.name },
      maxlength: {
        name: ValidationConstant.paymentInfo.SecurityCode.name,
        max: ValidationConstant.paymentInfo.SecurityCode.maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.paymentInfo.SecurityCode.name,
        min: ValidationConstant.paymentInfo.SecurityCode.minLength.toString(),
      },
    },
    country: {
      required: { name: ValidationConstant.paymentInfo.country.requireName },
      pattern: { name: ValidationConstant.paymentInfo.country.name },
      countrySelect: { name: 'country' },
    },
    state: {
      required: { name: ValidationConstant.paymentInfo.state.requireName },
      pattern: { name: ValidationConstant.paymentInfo.state.name },
      stateSelect: { name: 'state' },
    },
    street: {
      required: { name: ValidationConstant.paymentInfo.street.requireName },
      maxlength: {
        name: ValidationConstant.paymentInfo.street.name,
        max: ValidationConstant.paymentInfo.street.maxLength.toString(),
      },
      pattern: { name: ValidationConstant.paymentInfo.street.name },
    },
    houseNo: {
      required: { name: ValidationConstant.paymentInfo.houseNo.requireName },
      maxlength: {
        name: ValidationConstant.paymentInfo.houseNo.name,
        max: ValidationConstant.paymentInfo.houseNo.maxLength.toString(),
      },
      pattern: { name: ValidationConstant.paymentInfo.houseNo.name },
    },
    postalCode: {
      required: { name: ValidationConstant.paymentInfo.postalCode.requireName },
      minlength: {
        name: ValidationConstant.paymentInfo.postalCode.name,
        min: this.postalCodeMinLength,
      },
      pattern: { name: ValidationConstant.paymentInfo.postalCode.name },
    },
    city: {
      required: { name: ValidationConstant.paymentInfo.city.requireName },
      maxlength: {
        name: ValidationConstant.paymentInfo.city.name,
        max: ValidationConstant.paymentInfo.city.maxLength.toString(),
      },
      pattern: { name: ValidationConstant.paymentInfo.city.name },
    },
  };

  paymentObj = Object.assign({
    token: '',
    billingcontact: {
      name: {
        firstname: '',
        lastname: '',
      },
      companyName: null,
      phone: '',
      email: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: 1,
        postalCode: '',
      },
      ISDCode : null
    },
    transactionCode: 'WEB',
    channelType: '',
    tenderInfo: {
      cardHolderName: '',
      cardType: '',
      cardNumber: null,
      cardExpiry: null,
      cvDataStatus: 'AV',
      cvv: '',
      createAccountToken: false,
      removeDiscounting: false,
      Amount: null,
      AccountHolderName: '',
      routingNumber: '',
      bankName: '',
      accountNumber: '',
      verifyAccountNumber: '',
      // accountType: 'checking',
    },
    type: 'AnytimePayment',
    AdditionalFields: {
      invoicenumber: null,
      other: null,
      policynumber: null,
      accountnumber: null,
      shoppercompanyname: null,
      purposeofpayment: null,
    },
    SaveCustomer: false,
  });
  paymentErrors: any;
  showCreditFee: boolean = true;
  countriesList: string;
  stateList: string;
  loader: boolean;
  cardNumberLength: any = 16;
  apiError: boolean = false;
  cardAllowed: boolean = true;
  allowedTransactionTypes: any;
  checkedValue: any;
  checkedValueCard: any;
  checkedValueACH: any;
  cardInvalid: boolean = false;

  modelConfig = {
    backdrop: false,
    ignoreBackdropClick: true,
  };

  states = [];
  displayErrorMessage: any;
  paymentErrorDisplayFinal: any = [];
  correctError: boolean = false;
  paymentErrorMessage: any[];
  error401: boolean = false;
  amountLoaded: boolean = false;
  isAmountZero: boolean = false;
  uuidValue;
  showDropdown: boolean;
  showStateDropdown: boolean;
  selectedOption: any;
  selectedState: any;
  paymentAmount: any;
  visitedFlag: boolean = false;
  debit = true;
  credit = true;
  ach = true;
  paymentAcceptanceLimit: any;
  achPaymmentLimit: any;
  creditPaymentLimit: any;
  debitPaymentLimit: any;
  highestPaymentAcceptanceLimit: number;
  lowestPaymentAcceptanceLimit: number;
  isdCode: any;
  featureFlags: any;
  selectedBankType: string = '';
  selectedAccountType: string = '';
  BankTypeSelected: boolean = true;
  AccTypeSelected: boolean = true;
  iniCardType: any;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private headerService: HeaderStatusService,
    private storageService: StorageService,
    private paymentInformationService: PaymentInformationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private numberPipe: DecimalPipe,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.validator = new Validator(this.config);
  }

  ngOnInit(): void {
    //  let sessionData=
    // let sessionData = sessionStorage.getItem('agentSettings');
    this.featureFlags=this.launchDarklyService.launchDarklyAllFlags;
    this.launchDarklyService.launchDarklyEmitter.subscribe(allFlags => {
      this.featureFlags = allFlags;
      this.launchDarklyService.launchDarklyAllFlags=allFlags;
    });
    let sessionData = this.storageService.get(
      StorageType.session,
      'agentSettings'
    );

    this.countriesList = this.storageService.get(
      StorageType.local,
      'countryList'
    );

    this.stateList = this.storageService.get(StorageType.local, 'stateList');
    let paymentAcceptanceLimitRaw = this.storageService.get(StorageType.session, 'paymentAcceptanceLimit');
    let paymentAcceptanceLimit
    if (paymentAcceptanceLimitRaw !== undefined && paymentAcceptanceLimitRaw !== null && paymentAcceptanceLimitRaw !== 'undefined') {
        paymentAcceptanceLimit = JSON.parse(paymentAcceptanceLimitRaw || '{}');
    } else {
        paymentAcceptanceLimit = {};
    }
    this.settings = JSON.parse(sessionData || '{ }');

    this.merchantName = this.settings.name;
    let sessionContactInfo = this.storageService.get(
      StorageType.session,
      'contactInfo'
    );
    this.isdCode = JSON.parse( this.storageService.get(
      StorageType.session,
      'countryCode',
    ));
    this.contactInfo = JSON.parse(sessionContactInfo || '{ }');
    this.createPaymentInfoForm();
    this.createACHForm();
    this.createCardForm();
    this.lookupState('');
    this.lookupCountry();
    this.initErrors();
    this.getPaymentAcceptanceObject(paymentAcceptanceLimit)
    this.paymentInfoForm.valueChanges.subscribe(() =>
      this.onValueChanged('paymentInfoForm')
    );
    this.addACHForm.valueChanges.subscribe(() =>
      this.onValueChanged('addACHForm')
    );

    this.checkedValueCard =
      this.settings?.paymentAuthorizationStatement?.filter(
        (cardDetail) =>
          cardDetail.processMethod.toUpperCase().includes('CARD') &&
          cardDetail.pasType == 'PayInFull'
      )[0];
    this.checkedValueCard =
      this.checkedValueCard !== undefined ? this.checkedValueCard : {};
    this.checkedValueACH = this.settings?.paymentAuthorizationStatement?.filter(
      (achDetail) =>
        achDetail.processMethod.toUpperCase() == 'ACH' &&
        achDetail.pasType == 'PayInFull'
    )[0];

    this.checkedValueACH =
      this.checkedValueACH !== undefined ? this.checkedValueACH : {};

    setTimeout(() => {
      this.refreshEvent();
    }, 100);

    this.generateRandomUniqueID();
    this.selectedCountry = 1
    this.allowTransctionProcess()
  }

  generateRandomUniqueID() {
    const url = URL.createObjectURL(new Blob())
    const [id] = url.toString().split('/').reverse()
    URL.revokeObjectURL(url)
    this.uuidValue = id;
  }

  refreshEvent() {
    const refInput = this.el?.nativeElement?.querySelectorAll('input');
    if (refInput.length > 0) {
      for (let item of refInput) {
        if (item.type == 'text' || item.type == 'password') {
          let label = item.parentNode.getElementsByTagName('label')[0];
          let inline = item.parentNode;
          item.addEventListener('focus', () => {
            label.classList.add('animate');
            //inline.classList.add('move');
          });
          item.addEventListener('focusout', () => {
            if (item.value == '') label.classList.remove('animate');
            //inline.classList.remove('move');
          });
        }
      }
    }
    const refSelect = this.el?.nativeElement?.querySelectorAll('select');
    if (refSelect.length > 0) {
      for (let item of refSelect) {
        let label = item.parentNode.getElementsByTagName('label')[0];
        let inline = item.parentNode;
        item.addEventListener('focus', () => {
          label.classList.add('animate');
          // inline.classList.add('move');
        });
        item.addEventListener('focusout', () => {
          if (item.value == '') label.classList.remove('animate');
          //inline.classList.remove('move');
        });
      }
    }
  }

  createPaymentInfoForm() {
    this.paymentInfoForm = this.fb.group({
      Amount: [
        '',
        [
          Validators.required,
          Validators.maxLength(ValidationConstant.paymentInfo.amount.maxLength),
          Validators.pattern(ValidationConstant.amount_regex),
        ],
      ],
      // channelType:['',[]],
      ACH: this.fb.group({
        fee: ['', []],
        total: ['', []],
      }),
      CARD: this.fb.group({
        debitFee: ['', []],
        debitTotal: ['', []],
        creditFee: ['', []],
        creditTotal: ['', []],
      }),
    });
  }
  createACHForm() {
    this.addACHForm = this.fb.group(
      {
        accountHolderName: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.accountHolderName.maxLength
            ),
            Validators.pattern(
              ValidationConstant.accountHolderNameRegex
            ),
          ],
        ],
        routingNumber: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.routingNumber.maxLength
            ),
            Validators.minLength(
              ValidationConstant.paymentInfo.routingNumber.minLength
            ),
            Validators.pattern(ValidationConstant.numberRegex),
          ],
        ],
        accountNumber: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.accountNumber.maxLength
            ),
            Validators.pattern(ValidationConstant.numberRegex),
          ],
        ],
        verifyAccountNumber: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.verifyAccountNumber.maxLength
            ),
            Validators.pattern(ValidationConstant.numberRegex),
          ],
        ],
        accountType: ['', []],
        bankName: ['', []],
        saveForFuture: ['', []],
        // achAgreement: ['', [Validators.required]],
        // address: this.initAddress(),
      },
      {
        validator: PasswordValidation.MatchAccountNumber, // custom validator
      }
    );
  }

  createCardForm() {
    this.addCardForm = this.fb.group(
      {
        NameOnCard: [
          '',
          [
            Validators.required,
            Validators.pattern(
              ValidationConstant.cardHolderNameRegex
            ),
            Validators.maxLength(
              ValidationConstant.paymentInfo.NameOnCard.maxLength
            ),
            // Validators.minLength(ValidationConstant.paymentInfo.NameOnCard.minLength),
          ],
        ],
        CardNumber: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.CardNumber.maxLength
            ),
            Validators.minLength(
              ValidationConstant.paymentInfo.CardNumber.minLength
            ),
            Validators.pattern(ValidationConstant.cardNumberRegex),
          ],
        ],
        cardExpiry: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.cardExpiry.maxLength
            ),
            Validators.minLength(
              ValidationConstant.paymentInfo.cardExpiry.minLength
            ),
            // Validators.pattern(ValidationConstant.expiryDate),
          ],
        ],
        SecurityCode: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.SecurityCode.maxLength
            ),
            Validators.minLength(
              ValidationConstant.paymentInfo.SecurityCode.minLength
            ),
            Validators.pattern(ValidationConstant.numberRegex),
          ],
        ],
        saveForFuture: ['', []],
        // cardAgreement: ['', [Validators.required]],
        address: this.initAddress(),
      },
      {
        validator: [CardValidation.valid_card, CardValidation.card_Expiry],
      }
    );

    this.addCardForm.valueChanges.subscribe((_data: any) =>{
      this.onValueChanged('addCardForm');
      this.checkCardNumberUndefined();
      this.checkSubstring();
    }
    );
    // this.fb.array([])
  }

  checkCardNumberUndefined(){
    if(!this.iniCardType && this.addCardForm?.get('CardNumber')?.value?.length > 0){
      this.addCardForm.get('SecurityCode').setValidators([
        Validators.required,
      ]);
      this.cardInvalid = true;
      this.defaultAllCards = false;
      this.cardImageName = '../../../../assets/images/BlankCard.png';
      this.addCardErrors['CardNumber']= MessageSetting?.payments?.invalidCardError;
    }
  }

  initAddress() {
    return this.fb.group(
      {
        country: ['1', [Validators.required]],
        state: ['', [Validators.required]],
        street: ['', [Validators.maxLength(ValidationConstant.paymentInfo.street.maxLength)],],
        houseNo: ['', [Validators.required, Validators.maxLength(ValidationConstant.paymentInfo.houseNo.maxLength),],],
        postalCode: ['', [Validators.required]],
        city: [
          '',
          [
            Validators.required,
            Validators.maxLength(ValidationConstant.paymentInfo.city.maxLength),
            Validators.pattern(
              ValidationConstant.cityRegex,
            ),
          ],
        ],
      },
      {
        validator: [CardValidation.country_select, CardValidation.state_select],
      }
    );
  }

  get formcontrol() {
    return this.paymentInfoForm?.controls;
  }
  get Cardformcontrol() {
    return this.addCardForm.controls;
  }
  get ACHformcontrol() {
    return this.addACHForm.controls;
  }

  formatExpiry(event) {
    const key = event.key;
    const inputValue = event.target.value;

    if (/^\d{0,2}$/.test(inputValue)) {
      // Only allow numbers if input length is less than 2
      if (/^\d$/.test(key)) {
        if (inputValue.length === 2) {
          event.target.value = inputValue + '/';
        }
        return;
      }
      // Only allow slash if input length is less than 3
      if (key === '/' && inputValue.length < 3) {
        event.target.value = inputValue;
        return;
      }
    }

    // Filter out any non-numeric characters
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== '/') {
      event.preventDefault();
    }

    // Remove extra slash
    if ((key === 'Backspace' || key === 'Delete') && inputValue.length === 4) {
      event.target.value = inputValue.slice(0, 3);
    }

    // Move to the next or previous input on Tab press
    if (key === 'Tab') {
      const inputs = document.getElementsByTagName('input');
      const currentIndex = Array.prototype.indexOf.call(inputs, event.target);
      if (event.shiftKey) {
        // Move to the previous input
        if (currentIndex > 0) {
          inputs[currentIndex - 1].focus();
        }
      } else {
        // Move to the next input
        if (currentIndex < inputs.length - 1) {
          inputs[currentIndex + 1].focus();
        }
      }
      event.preventDefault();
    }
  }

  addValidationCvv(cardBrand) {
    if (cardBrand == 'AMEX' || cardBrand == 'AMERICAN EXPRESS') {
      this.addCardForm.get('SecurityCode').clearValidators();
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.addCardForm
        .get('SecurityCode')
        .addValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.SecurityCodeExpress.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.SecurityCodeExpress.minLength
          ),
          Validators.pattern(ValidationConstant.numberRegex),
        ]);
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.securityCodeLength = 4;

      this.addCardForm.get('CardNumber').clearValidators();
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.addCardForm
        .get('CardNumber')
        .setValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.CardNumberExpress.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.CardNumberExpress.minLength
          ),
          Validators.pattern(ValidationConstant.cardNumberRegex),
        ]);
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.cardNumberLength = 15;
    } else if (cardBrand == 'DINERS CLUB INTERNATIONAL') {
      this.addCardForm.get('SecurityCode').clearValidators();
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.addCardForm
        .get('SecurityCode')
        .addValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.SecurityCodeDiners.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.SecurityCodeDiners.minLength
          ),
          Validators.pattern(ValidationConstant.numberRegex),
        ]);
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.securityCodeLength = 3;

      this.addCardForm.get('CardNumber').clearValidators();
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.addCardForm
        .get('CardNumber')
        .setValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.CardNumberDiners.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.CardNumberDiners.minLength
          ),
          Validators.pattern(ValidationConstant.cardNumberRegex),
        ]);
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.cardNumberLength = 14;
    }
     else {
      this.addCardForm.get('SecurityCode').clearValidators();
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.addCardForm
        .get('SecurityCode')
        .addValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.SecurityCode.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.SecurityCode.minLength
          ),
          Validators.pattern(ValidationConstant.numberRegex),
        ]);
      this.addCardForm.get('SecurityCode').updateValueAndValidity();
      this.securityCodeLength = 3;

      this.addCardForm.get('CardNumber').clearValidators();
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.addCardForm
        .get('CardNumber')
        .setValidators([
          Validators.required,
          Validators.maxLength(
            ValidationConstant.paymentInfo.CardNumber.maxLength
          ),
          Validators.minLength(
            ValidationConstant.paymentInfo.CardNumber.minLength
          ),
          Validators.pattern(ValidationConstant.cardNumberRegex),
        ]);
      this.addCardForm.get('CardNumber').updateValueAndValidity();
      this.cardNumberLength = 16;
    }
  }

  addValidation(controlName: any, country: any, activeTab: any) {
    if (activeTab == 'CARD') {
      this.Cardformcontrol.address.get(controlName)?.clearValidators();
      this.Cardformcontrol.address.get(controlName)?.updateValueAndValidity();
      if (country == '1') {
        this.Cardformcontrol.address.controls[controlName]?.addValidators([
          Validators.required,
          // Validators.minLength(
          //   ValidationConstant.paymentInfo.postalCode.minLength
          // ),
        ]);
        this.Cardformcontrol.address.get(controlName)?.updateValueAndValidity();
        this.postalCodeLength = 10;
      }
      if (country == '2') {
        this.Cardformcontrol.address.controls[controlName]?.addValidators([
          Validators.required,
          // Validators.minLength(
          //   ValidationConstant.paymentInfo.postalCode.minLength
          // )
        ]);
        this.Cardformcontrol.address.get(controlName)?.updateValueAndValidity();
        this.postalCodeLength = 10;
      }
    } else {
      // this.ACHformcontrol?.address?.get(controlName)?.clearValidators();
      // this.ACHformcontrol?.address?.get(controlName)?.updateValueAndValidity();

      // if (country == '1') {
      //   this.ACHformcontrol.address.controls[controlName].addValidators([
      //     Validators.required,
      //     // Validators.pattern(ValidationConstant.zipcode_USA_regex),
      //   ]);
      //   this.ACHformcontrol.address.get(controlName).updateValueAndValidity();
      //   this.postalCodeLength = 10;
      // }
      // if (country == '2') {
      //   this.ACHformcontrol.address.controls[controlName]?.addValidators([
      //     Validators.required,
      //     //Validators.pattern(ValidationConstant.zipcode_Canada_regex),
      //   ]);
      //   this.ACHformcontrol.address.get(controlName)?.updateValueAndValidity();
      //   this.postalCodeLength = 10;
      // }
    }
  }

  addPostalValidation(controlName: any, country: any, activeTab: any) {
    if (activeTab == 'CARD') {
      this.Cardformcontrol.address.get(controlName)?.reset();
      country = country?.toString()
      if (country == '1') {
        // this.Cardformcontrol.address.controls[controlName]?.addValidators([
        //   Validators.required

        // ]);
        this.postalCodeLength = 10;
        this.postalCodeMask = '00000-0000';
      }
      if (country == '2') {
        // this.Cardformcontrol.address.controls[controlName]?.addValidators([
        //   Validators.required
        // ]);
        //this.Cardformcontrol.address.get(controlName)?.updateValueAndValidity();
        this.postalCodeLength = 7;
        this.postalCodeMask = 'S0S 0S0';
      }
      if(country !== '1' && country !== '2'){
        this.postalCodeLength = 12
        this.postalCodeMask = ""
      }

      // this.Cardformcontrol.address.get(controlName)?.updateValueAndValidity();
      if (this.addCardForm.controls.cardAgreement?.value == true) {
        this.addCardForm.controls.address.controls.postalCode.setErrors({
          required: true,
        });
        this.validator = new Validator(this.config);
        this.validator.validateAllFormFields(this.addCardForm);
        this.addCardErrors = this.validator.validate(this.addCardForm);
      }
    } else {
      // this.ACHformcontrol?.address?.get(controlName)?.clearValidators();
      // this.ACHformcontrol?.address?.get(controlName)?.updateValueAndValidity();

      // if (country == '1') {
      //   this.ACHformcontrol.address.controls[controlName].addValidators([
      //     Validators.required,
      //     // Validators.pattern(ValidationConstant.zipcode_USA_regex),
      //   ]);
      //   this.ACHformcontrol.address.get(controlName).updateValueAndValidity();
      //   this.postalCodeLength = 10;
      // }
      // if (country == '2') {
      //   this.ACHformcontrol.address.controls[controlName]?.addValidators([
      //     Validators.required,
      //     //Validators.pattern(ValidationConstant.zipcode_Canada_regex),
      //   ]);
      //   this.ACHformcontrol.address.get(controlName)?.updateValueAndValidity();
      //   this.postalCodeLength = 10;
      // }
    }
  }

  onValueChanged(data?: any) {
    if (data === 'paymentInfoForm') {
      if (!this.paymentInfoForm) {
        return;
      }
      this.paymentInfoFormErrors = this.validator.validate(
        this.paymentInfoForm
      );
    }
    if (data === 'addACHForm') {

      if (!this.addACHForm) {
        return;
      }
      this.addACHFormErrors = this.validator.validate(this.addACHForm);
    }
    if (data === 'addCardForm') {
      if (!this.addCardForm) {
        return;
      }
      this.addCardErrors = this.validator.validate(this.addCardForm);
    }
  }
  checkSubstring(){
    let nameOnCard = this.addCardForm?.get('NameOnCard')?.value;
    let cardNumber = this.addCardForm?.get('CardNumber')?.value;
    let cleanedNameOnCard = nameOnCard?.replace(/\s+/g, '')?.replace(/[^0-9]/g, '');
    // this.addCardForm.get('NameOnCard').setErrors(null);
    if(nameOnCard?.length == 0 && this.visitedFlag){
      this.addCardErrors.NameOnCard = "Please enter a name on card.";
    }
    if (cardNumber?.length >= 6 && cleanedNameOnCard?.length >= 6) {
      for (let i = 6; i <= cardNumber?.length; i++) {
        for (let j = 0; j <= cardNumber?.length - i; j++) {
          const substring = cardNumber?.substr(j, i);
          if (cleanedNameOnCard?.includes(substring)) {
              this.addCardForm?.get('NameOnCard')?.setErrors({NameOnCard : true});
              this.addCardErrors.NameOnCard = "Please enter a valid Name On Card.";
           return;
          }
        }
      }
    }
    else{
      if((cardNumber?.length > 0 && cleanedNameOnCard?.length > 0) && (cardNumber?.length < 6 || cleanedNameOnCard?.length < 6)){
        if (ValidationConstant.cardHolderNameRegex.test(nameOnCard)){
          this.addCardForm?.get('NameOnCard')?.setErrors(null);
          this.addCardErrors.NameOnCard = "";
        }
      }
    }
  }

  CalculateFee() {
    if (this.paymentInfoForm.invalid) {
      this.validator.validateAllFormFields(this.paymentInfoForm);
      this.paymentInfoFormErrors = this.validator.validate(
        this.paymentInfoForm
      );
    } else if(Number(this.paymentInfoForm?.value?.Amount) !== 0 && this.paymentInfoForm?.value?.Amount?.length){
      this.showPaychoice = true;
      this.paymentAmount = this.paymentInfoForm?.value?.Amount
      this.processFees();

      setTimeout(() => {
        let cardBox = document.getElementById('cardBox');
        if (cardBox && cardBox.classList.contains('active')) {
          this.show_tab = 'CARD';
        } else {
          this.show_tab = 'ACH';
        }
      }, 500);
    }
  }

  omit_alpha_special_char_include_decimal(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57) || k == 8 || k == 46;
  }

  CalculateFeesUpdate(event) {
    const amountInput = document.getElementById('inputAmount');
    this.ngAfterViewInit()
    if (event.target.value.length == 0 || this.paymentInfoForm.invalid || Number(event.target.value) == 0 ) {
      this.paymentTypeChoice.CARD = false;
      this.paymentTypeChoice.ACH = false;
      this.amountLoaded = false
      this.isAmountZero = true;
      this.setCardStatus('',amountInput);
    } else {
      this.isAmountZero = false;
    }
    if (this.amountLoaded) {
      if (this.paymentInfoForm.invalid) {
        this.validator.validateAllFormFields(this.paymentInfoForm);
        this.paymentInfoFormErrors = this.validator.validate(
          this.paymentInfoForm
        );
      } else {
        this.paymentAmount = this.paymentInfoForm?.value?.Amount
        this.showPaychoice = true;
        this.processFees();
        setTimeout(() => {
          let cardBox = document.getElementById('cardBox');
          if (cardBox && cardBox.classList.contains('active')) {
            this.show_tab = 'CARD';
          } else {
            this.show_tab = 'ACH';
          }
        }, 500);
        setTimeout(() => {
          this.checkAmountThreshold(amountInput);
          this.debit = true;
          this.credit = true;
          this.ach = true;
          this.setCardStatus(event?.target?.value, amountInput);
        }, 0);

      }
    }
  }

  allowTransctionProcess() {
    Object.keys(this.allowTransctioList).forEach((value) => {
      this.allowTransctioList[value] =
        this.settings.allowedTransactionTypes?.some(
          (item) => item.channelType == value
        );
    });
    if ((this.allowTransctioList.CreditCard || this.allowTransctioList.DebitCard) && this.paymentAmount >= 10) {
      this.paymentTypeChoice.CARD = true;
      this.paymentTypeChoice.ACH = false;
    } else {
      this.paymentTypeChoice.CARD = false;
      if(this.allowTransctioList.ACH && Number(this.paymentAmount))this.paymentTypeChoice.ACH = true;
    }
  }

  processFees() {
    this.amountLoaded = true;
    this.allowTransctionProcess();
    let amount = this.paymentInfoForm.get('Amount').value;
    let defaultValues = {
      Amount: amount,
      ACH: {
        fee: 0,
        total: parseFloat(amount),
      },
      CARD: {
        debitFee: 0,
        debitTotal: parseFloat(amount),
        creditFee: 0,
        creditTotal: parseFloat(amount),
      },
    };

    this.paymentInfoForm.setValue(defaultValues);
    if (this.settings?.paymentAdjustments?.length > 0) {
      this.settings?.paymentAdjustments?.forEach((element) => {
        element?.applicableTo?.forEach((item) => {
          if (item == 'ACH') {
            let fee, total;
            fee =
              (amount * element.defaultPercentValue) / 100 +
              element.defaultFixedValue;
            total = parseFloat(amount) + parseFloat(fee);
            let ACH_Value = { fee: fee.toFixed(2), total: total.toFixed(2) };
            this.paymentInfoForm.get('ACH').setValue(ACH_Value);
            this.applicableToList.ACH = true;
            this.applicableToList.ACHMsg = `${element.descriptorMessage
              } \n For ${item} ${element.defaultPercentValue} percent and ${element.defaultFixedValue
              } dollars \n ${element.displayName} $ ${fee.toFixed(2)}`;
          }
          if (item == 'CreditCard') {
            let fee, total;
            fee =
              (amount * element.defaultPercentValue) / 100 +
              element.defaultFixedValue;
            total = parseFloat(amount) + parseFloat(fee);
            this.paymentInfoForm
              .get('CARD')
              .get('creditFee')
              .setValue(fee.toFixed(2));
            this.paymentInfoForm
              .get('CARD')
              .get('creditTotal')
              .setValue(total.toFixed(2));
            this.applicableToList.CreditCard = true;
            this.applicableToList.CreditCardMsg = `${element.descriptorMessage
              } \n For ${item} ${element.defaultPercentValue} percent and ${element.defaultFixedValue
              } dollars \n ${element.displayName} $ ${fee.toFixed(2)}`;
          }
          if (item == 'DebitCard') {
            let fee, total;
            fee =
              (amount * element.defaultPercentValue) / 100 +
              element.defaultFixedValue;
            total = parseFloat(amount) + parseFloat(fee);
            this.paymentInfoForm
              .get('CARD')
              .get('debitFee')
              .setValue(fee.toFixed(2));
            this.paymentInfoForm
              .get('CARD')
              .get('debitTotal')
              .setValue(total.toFixed(2));
            this.applicableToList.DebitCard = true;
            this.applicableToList.DebitCardMsg = `${element.descriptorMessage
              } \n For ${item} ${element.defaultPercentValue} percent and ${element.defaultFixedValue
              } dollars \n ${element.displayName} $ ${fee.toFixed(2)}`;
          }
        });
      });

      // Setting method values
      // if (this.isCalculated) {
      //   if (this.allowTransctioList.ACH) {
      //     this.selectPaymentMethod('ACH');
      //   } else if (this.allowTransctioList.CreditCard) {
      //     this.selectPaymentMethod('CreditCard');
      //   } else {
      //     this.selectPaymentMethod('CARD');
      //   }
      // }
    }
  }
  removeBiilingAddressValidation() {
    this.addACHForm.controls.address?.get('country').clearValidators();
    this.addACHForm.controls.address?.get('country').updateValueAndValidity();

    this.addACHForm.controls.address?.get('state').clearValidators();
    this.addACHForm.controls.address?.get('state').updateValueAndValidity();

    this.addACHForm.controls.address?.get('street').clearValidators();
    this.addACHForm.controls.address?.get('street').updateValueAndValidity();

    this.addACHForm.controls.address?.get('houseNo').clearValidators();
    this.addACHForm.controls.address?.get('houseNo').updateValueAndValidity();

    this.addACHForm.controls.address?.get('postalCode').clearValidators();
    this.addACHForm.controls.address?.get('postalCode').updateValueAndValidity();

    this.addACHForm.controls.address?.get('city').clearValidators();
    this.addACHForm.controls.address?.get('city').updateValueAndValidity();
    this.addACHForm.controls.address?.clearValidators();
    this.addACHForm.controls.address?.updateValueAndValidity();
  }

  showTab(input: string) {
    this.lookupState('');
    this.postalCodeMinLength = 5;
    setTimeout(() => {
      this.refreshEvent();
      this.payMethodSelectionStyle();
    }, 100);
    this.password = '';
    this.unmaskedPassword = '';
    this.show_tab = input;
    if (input == 'ACH') {
      this.visitedFlag = false;
      this.addCardForm.reset();

      this.removeBiilingAddressValidation();

      this.addCardForm.controls.address.controls.country.patchValue('1');
      // this.addCardForm.controls.address.controls.state.patchValue('AK');

      this.submitted = false;
      this.singleCard = false;
      this.cardAllowed = true;
      this.apiError = false;
      this.cardInvalid = false;
      this.cardImageName = '../../../../assets/images/icon-all-card.png';
      this.defaultAllCards = true;
    } else {
      this.selectedBankType = '';
      this.selectedAccountType = '';
      this.addCardForm.controls.address.controls.country.patchValue('1');
      // this.addCardForm.controls.address.controls.state.patchValue('AK');
      this.addACHForm.reset();
      this.submitted = false;
    }
    this.selectPaymentMethod(input);
    this.showError = false;
    this.ngAfterViewInit()
  }

  selectPaymentMethod(input) {
    const achBox = document.getElementById('achBox');
const cardBox = document.getElementById('cardBox');
    if((achBox?.classList?.contains('disabled')) && input == 'ACH') {
      achBox?.classList?.remove('active');
      return
    }
    if(cardBox?.classList?.contains('disabled') && input == 'CARD') {
      cardBox?.classList?.remove('active');
      return
    }
    //Display message after payment method is selected first time
    this.isCalculated = true;
    let obj = this.paymentTypeChoice;

    for (let prop in obj) {
      if (prop !== input) {
        this.paymentTypeChoice[prop] = false;
      } else {
        this.paymentTypeChoice[prop] = true;
      }
    }

    //Technology/convinience fee Message display

    let applicableTo: string = '';
    if (input == 'ACH') {
      this.removeBiilingAddressValidation();
      applicableTo = 'ACH';
      this.fee = this.paymentInfoForm.value.ACH.fee;
      this.totalAmount = this.paymentInfoForm.value.ACH.total;
      this.show_tab = 'ACH';

    } else if (input == 'CARD' || input == 'Credit') {
      applicableTo = 'CreditCard';
      this.fee = this.paymentInfoForm.value.CARD.creditFee;
      this.totalAmount = this.paymentInfoForm.value.CARD.creditTotal;
      this.show_tab = 'CARD';
    }
    if (input == 'Debit') {
      applicableTo = 'DebitCard';
      this.fee = this.paymentInfoForm.value.CARD.debitFee;
      this.totalAmount = this.paymentInfoForm.value.CARD.debitTotal;
      this.show_tab = 'CARD';
    }

    let messageFilter = this.settings?.paymentAdjustments?.filter(
      (item) => item?.applicableTo?.filter((t) => t == applicableTo)[0]
    )[0];
    if (messageFilter) {
      this.feeMessage = `${messageFilter.descriptorMessage} \n For ${applicableTo} ${messageFilter.defaultPercentValue} percent and ${messageFilter.defaultFixedValue} dollars \n ${messageFilter.displayName} $ ${this.fee}`;
    } else {
      this.feeMessage = `No ${this.settings?.paymentAdjustments[0]?.displayName
          ? this.settings?.paymentAdjustments[0]?.displayName
          : 'Technology Fees'
        } Set`;
    }
  }

  proccedForPayment() {
    const achBox = document.getElementById('achBox');
    const cardBox = document.getElementById('cardBox');
    this.ngAfterViewInit()
    this.isCalculated = false;
    this.processFees();
    this.proccedPaymentStatus = true;
    this.dynamicTitleChange(this.proccedPaymentStatus);
    if(cardBox?.classList?.contains('disabled')){
      this.allowTransctioList.CreditCard = false;
      this.allowTransctioList.DebitCard = false;
    }else if(achBox?.classList?.contains('disabled')){
      this.allowTransctioList.ACH = false;
    }

    setTimeout(() => {
      this.refreshEvent();
    }, 100);

    setTimeout(() => {
      this.payMethodSelectionStyle();
    }, 100);
  }

  payMethodSelectionStyle() {
    const selectorBox = document?.getElementById('selector-box');
    const payMethodLength =
      document?.getElementsByClassName('paySelect').length;
    // const selectorHeader = document?.getElementById('select-header');
    // let achForm = document.getElementById('ach-details');
    // let cardForm = document.getElementById('card-details');

    if (payMethodLength == 1) {
      if (this.allowTransctioList.ACH) {
        this.show_tab = 'ACH';
        if (selectorBox != null) {
          selectorBox.style.left = '25%';
        }
        // selectorHeader.style['justify-content'] = 'center';
      } else {
        this.show_tab = 'CARD';
        if (selectorBox != null) {
          selectorBox.style.left = '25%';
        }
        // selectorHeader.style['justify-content'] = 'center';
      }
    } else {
      const paySelectButtons = document.getElementsByClassName('paySelect');
      const ACHMethod = paySelectButtons[0]?.classList?.contains('select');

      if (ACHMethod) {
        //activate the ach form
        if (selectorBox != null) {
          selectorBox.style.left = '0%';
        }
      } else {
        //activate the card form
        if (selectorBox != null) selectorBox.style.left = '50%';
      }
    }
  }

  dynamicTitleChange(value: boolean) {
    this.newTitleEvent.emit(value);
  }

  confirmACHPurchase(template: TemplateRef<any>) {
    this.submitted = true;
    if (this.addACHForm.invalid || this.apiError) {
      this.validator.validateAllFormFields(this.addACHForm);
      this.addACHFormErrors = this.validator.validate(this.addACHForm);
    } else {
      this.createPaymentObject('ACH');
      this.modalRef = this.modalService.show(template, this.modelConfig);
    }
  }

  confirmCardPurchase(template: TemplateRef<any>) {
    this.submitted = true;
    if (this.addCardForm.invalid || !this.cardAllowed) {
      this.validator.validateAllFormFields(this.addCardForm);
      this.addCardErrors = this.validator.validate(this.addCardForm);
    } else {
      this.createPaymentObject('CARD');
      this.modalRef = this.modalService.show(template, this.modelConfig);
    }
  }

  lookupState(input) {
    this.showDropdown = false
    this.selectedOption = input
    if (this.elRefpostalCodelabel != undefined)
      this.elRefpostalCodelabel?.nativeElement?.classList?.remove('animate');
    this.lookupStateList = [];
    this.selectedCountry = input?.countryId?.toString() || input?.value || '1'
    this.addCardForm.controls.address.controls.country.value = this.selectedCountry?.toString();
    let stateId;
    if (input == null || input == '') {
      stateId = 1;
    } else {
      stateId = input?.countryId || input?.value
    }
    if(this.selectedCountry !== '1' && this.selectedCountry !== '2'){
      this.addCardForm.controls.address.controls.state?.clearValidators();
      this.addCardForm.controls.address.controls.postalCode?.clearValidators();

      this.addCardForm.controls.address?.get('state').patchValue(null);
    }else{
      this.addCardForm.controls.address.controls.postalCode?.setValidators([
        Validators.required,
      ]);
      this.addCardForm.controls.address?.get('state').setValidators([
        Validators.required,
      ]);

    }
    if (this.stateList) {
      let initialStateList = JSON.parse(this.stateList) || null;

      initialStateList.forEach((element) => {
        if (stateId == element.countryId) {
          this.lookupStateList.push(element);
        }
      });

      let result = this.lookupStateList.find((o) => o.abbreviation === 'PR');
      this.states = [];
      if (result != undefined) this.states.push(result);
      let index = this.lookupStateList.findIndex(
        (o) => o.abbreviation === 'PR'
      );
      if (index > 0) this.lookupStateList.splice(index, 1);
    }
    this.addPostalValidation('postalCode', stateId, this.show_tab);
    if (stateId == 1) {
      this.addCardForm.controls.address.controls.state.patchValue('AK');
    } else if (stateId == 2) {
      this.addCardForm.controls.address.controls.state.patchValue('AB');
    }
  }
  lookupCountry() {
    if (this.countriesList) {
      this.lookupCountryList = JSON.parse(this.countriesList);
    }
  }

  getCardTypeName(cardNumber) {
    let cardObj:any = {};
    let returnName = "";
    let id = 0;

    let re = new RegExp("^4");
    if (cardNumber.match(re) != null) {
      returnName = "VISA";
      id = 1;
    }

    re = new RegExp(
      "^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)"
    );
    if (cardNumber.match(re) != null) {
      returnName = "MASTERCARD";
      id = 2;
    }

    re = new RegExp("^3[47]");
    if (cardNumber.match(re) != null) {
      returnName = "AMEX";
      id = 3;
    }

    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|64[4-9][0-9]|65|6282)"
    );
    if (cardNumber.match(re) != null) {
      returnName = "DISCOVER";
      id = 4;
    }

    re = new RegExp("^36");
    if (cardNumber.match(re) != null) {
      returnName = "DINERS";
      id = 5;
    }

    re = new RegExp("^30[0-5]");
    if (cardNumber.match(re) != null) {
      returnName = "DINERS";
      id = 5;
    }

    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (cardNumber.match(re) != null) {
      returnName = "JCB";
      id = 6;
    }

    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (cardNumber.match(re) != null) {
      returnName = "Visa Electron";
      id = 7;
    }

    cardObj.returnName = returnName;
    cardObj.id = id;
    cardObj.regex = re;
    return cardObj;
  }


  cardBinLookup(event: any) {
    if (event) {
      if (event.target?.value.length == 0) {
        setTimeout(() => {
        this.cardAllowed = true;
        this.cardInvalid = false;
        this.cardNumberLength = 16;
        this.addCardForm.get('CardNumber').clearValidators();
        this.addCardForm.get('CardNumber').updateValueAndValidity();
        this.addCardForm
          .get('CardNumber')
          .setValidators([
            Validators.required,
            Validators.maxLength(
              ValidationConstant.paymentInfo.CardNumberExpress.maxLength
            ),
            Validators.minLength(
              ValidationConstant.paymentInfo.CardNumberExpress.minLength
            ),
            Validators.pattern(ValidationConstant.cardNumberRegex),
          ]);
        this.addCardForm.get('CardNumber').updateValueAndValidity();
        }, 2000);
      }
      if (
        event.target?.value.length == 8 ||
        event.target?.value.length == 16 ||
        event.target?.value.length == 15 ||
        event.target?.value.length == 14
      ) {
        this.cardInvalid = false;
        this.cardAllowed = true;
        this.cardCalledInputs.push(event.target.value);
        this.cardDetails = {};
        let cardParam = {
          binNumber: event?.target?.value?.slice(0, 8),
        };
        this.iniCardType = this.getCardTypeName(event.target.value).returnName;
        if (this.iniCardType != "" && event.target.value.length >= 6) {
          this.paymentInformationService.binLookUp(cardParam).subscribe({
            next: (bindata: any) => {
              this.cardDetails = bindata;
              this.globalCardType = bindata.cardType;

              this.allowedTransactionTypes =
                this.settings.allowedTransactionTypes;

              let channelType = {
                credit: this.allowedTransactionTypes.some((i) =>
                  i.channelType.includes('CreditCard')
                ),
                debit: this.allowedTransactionTypes.some((i) =>
                  i.channelType.includes('DebitCard')
                ),
                ach: this.allowedTransactionTypes.some((i) =>
                  i.channelType.includes('ACH')
                ),
              };

              if (bindata.cardType.toLowerCase() == 'credit') {
                if (channelType.credit) {
                  let channelSubtype = [];
                  this.allowedTransactionTypes.forEach((ele) => {
                    if (ele.channelType == 'CreditCard') {
                      if (ele.channelSubType.toLowerCase() == 'amex') {
                        channelSubtype.push('americanexpress');
                        channelSubtype.push('american express');
                      }
                      if (ele.channelSubType.toLowerCase() == 'diners') {
                        channelSubtype.push('diners club international');
                        }
                      channelSubtype.push(ele.channelSubType.toLowerCase());
                    }
                  });

                  let checkCardNumber = channelSubtype.includes(
                    bindata.cardBrand.toLowerCase()
                  );

                  if (!checkCardNumber) {
                    this.cardAllowed = false;
                  } else {
                    this.cardAllowed = true;
                  }
                } else {
                  this.cardAllowed = false;
                }
              }
              if (bindata.cardType.toLowerCase() == 'debit') {
                if (channelType.debit) {
                  this.cardAllowed = true;
                } else {
                  this.cardAllowed = false;
                }
              }

              this.singleCard = true;
              this.cardImageName =
                '../../../../assets/images/icon-' +
                bindata?.cardBrand?.replace(/\s+/g, '-').toLowerCase() +
                '.png';
              this.defaultAllCards = false;

              if (bindata.cardType == 'DEBIT') {
                this.showCreditFee = false;
                this.show_tab = 'CARD';
              } else if (bindata.cardType == 'CREDIT') {
                this.showCreditFee = true;
                this.show_tab = 'CARD';
              }

              if (!bindata.isValid) {
                this.cardInvalid = true;
                this.cardImageName = '../../../../assets/images/invalid-icon.svg';
                this.defaultAllCards = false;
                this.addCardForm.controls['CardNumber'].setErrors({
                  pattern: true,
                });
              }

              this.addValidationCvv(bindata.cardBrand);
            },
          }); ///
        } else if(!this.iniCardType || this.addCardErrors['CardNumber'] == undefined){
          this.cardInvalid = true;
          this.defaultAllCards = false;
          this.cardImageName = '../../../../assets/images/BlankCard.png';
          this.addCardErrors['CardNumber']= MessageSetting.payments.invalidCardError;
        }else {
          this.cardInvalid = true;
          this.defaultAllCards = false;
          this.cardImageName =
            '../../../../assets/images/BlankCard.png';
          this.addCardErrors['CardNumber'] = MessageSetting.payments.invalidCardError;

        }
      }
      if (event.target?.value.length < 8) {
        this.singleCard = false;
        this.defaultAllCards = true;
        this.cardImageName = '../../../../assets/images/icon-all-card.png';
      }
    }
  }
  routingLookup(event: any) {
    this.apiError = false;
    if (event?.target?.value?.length == 9) {
      let routingParam = {
        routingNumber: event.target.value,
      };
      // this.elRefbankName.nativeElement.focus();
      this.paymentInformationService.routingLookUp(routingParam).subscribe({
        next: (data: any) => {
          if (data.bankName) {
            this.addACHForm.get('bankName').patchValue(data.bankName);
            this.readonlyBankField = true;
            this.apiError = false;
            this.elRefinputRoutingLabel?.nativeElement?.classList.add(
              'animate'
            );

            // let xxx = (<any>this.addACHForm.get('bankName'));
            // xxx.nativeElement.focus();
          } else {
            this.readonlyBankField = false;
          }
        },
        error: (e) => {
          this.elRefinputRoutingLabel?.nativeElement?.classList?.remove(
            'animate'
          );
          this.addACHForm.get('bankName').patchValue('');
          this.apiError = true;
        },
      });
    }
  }
  updateStatus(data) {
    this.headerService.updateStatus(data);
  }
  closeModal() {
    this.modalRef?.hide();
  }

  SendPayment(type?) {
    if(!this.paymentSubmitted){
      this.isConfirmDisabled = false;
      // let submitButton = document.getElementById('submitButton');
      // submitButton.addEventListener('keyup',(event)=>{
      //    if(event.key == 'Enter'){
      //     this.paymentSubmitted = true
      //     }
      // })
      setTimeout(() => {
        this.isConfirmDisabled = true;
      }, 3000);

      if (this.show_tab) {
        if (this.show_tab == 'ACH') {
           this.validator = new Validator(this.config);
           this.validator.validateAllFormFields(this.addACHForm);
           this.addACHFormErrors = this.validator.validate(this.addACHForm);
          if(this.selectedAccountType == ''){
            this.AccTypeSelected = false
          }
          if(this.selectedBankType == ''){
            this.BankTypeSelected = false
          }
          if (this.addACHForm.invalid) {
            return;
          }
          if((this.selectedBankType == '' || this.selectedAccountType == '') && this.featureFlags['ach-classification-enforcement']){
            return
          }

        } else if (this.show_tab == 'CARD') {
           this.validator = new Validator(this.config);
           this.validator.validateAllFormFields(this.addCardForm);
           this.addCardErrors = this.validator.validate(this.addCardForm);
           this.checkCardNumberUndefined();
          if (this.addCardForm.invalid) {
            return;
          }
        }

      }

      this.subscription = this.recaptchaV3Service
        .execute('anytimePayment')
        .subscribe((token) => this.callPaymentAPI(token, type));

      this.modalRef?.hide();
    }
  }

  backToContactInfo() {
    let headerStatus = {
      contactInfo: 'active',
      paymentInfo: 'pending',
      summaryInfo: 'pending',
    };
    this.updateStatus(headerStatus);
  }

  createPaymentObject(processInput) {
    this.paymentObj.token = this.settings.token;
    this.paymentObj.billingcontact.name.firstname = this.contactInfo.FirstName;
    this.paymentObj.billingcontact.name.lastname = this.contactInfo.LastName;
    this.paymentObj.billingcontact.phone = this.contactInfo.Phone;
    this.paymentObj.billingcontact.email = this.contactInfo.Email;
    this.paymentObj.billingcontact.companyName = this.contactInfo.Company;
    this.paymentObj.billingcontact.ISDCode = `+${this.isdCode}`;
    if (processInput == 'CARD') {
      this.paymentObj.billingcontact.address = this.addCardForm
        .get('address')
        .getRawValue();
      this.paymentObj.billingcontact.address.addressLine1 = this.addCardForm
        .get('address')
        .get('houseNo').value;
      this.paymentObj.billingcontact.address.addressLine2 = this.addCardForm
        .get('address')
        .get('street').value;
      if (this.cardDetails != '' && this.cardDetails != null) {
        if (this.cardDetails.cardType == 'CREDIT')
          this.paymentObj.channelType = 'CreditCard';
        else this.paymentObj.channelType = 'DebitCard';

        this.paymentObj.tenderInfo.cardHolderName =
          this.addCardForm.get('NameOnCard').value.trimEnd();
          if(this.cardDetails.cardBrand == 'DINERS CLUB INTERNATIONAL'){
            this.cardDetails.cardBrand = 'Diners';
          }
        this.paymentObj.tenderInfo.cardType = this.cardDetails.cardBrand;
        this.paymentObj.tenderInfo.cardNumber =
          this.addCardForm.get('CardNumber').value;
        this.paymentObj.tenderInfo.cardExpiry = this.getOriginalExpiryDate(
          this.addCardForm.get('cardExpiry').value
        );
        this.paymentObj.SaveCustomer = this.addCardForm.get('saveForFuture')
          .value
          ? true
          : false;

        this.paymentObj.tenderInfo.cvv =
          this.addCardForm.get('SecurityCode').value;
        let cardFields = [
          'AccountHolderName',
          'routingNumber',
          'bankName',
          'accountNumber',
          'verifyAccountNumber',
        ];
        cardFields.forEach((item) => {
          delete this.paymentObj.tenderInfo[item];
        });
      }

      // const postalCode = this.addCardForm.controls.address.controls.postalCode.value;
      // if(postalCode && postalCode.length == 9){
      //     const updatedPostalCode = postalCode.substring(0, 5) + '-' + postalCode.substring(5);
      //     this.addCardForm.controls.address.controls.postalCode?.setValue(updatedPostalCode);
      //     this.paymentObj.billingcontact.address.postalCode = updatedPostalCode;
      // }

      this.paymentObj.billingcontact.address.postalCode= this.paymentObj.billingcontact?.address?.postalCode?.toUpperCase();

    }
    if (processInput == 'ACH') {
      // this.paymentObj.billingcontact.address = this.addACHForm
      //   .get('address')
      //   .getRawValue();
      // this.paymentObj.billingcontact.address.addressLine1 = this.addACHForm
      //   .get('address')
      //   .get('houseNo').value;
      // this.paymentObj.billingcontact.address.addressLine2 = this.addACHForm
      //   .get('address')
      //   .get('street').value;
      this.paymentObj.channelType = 'ACH';
      this.paymentObj.tenderInfo.AccountHolderName =
        this.addACHForm.get('accountHolderName').value.trimEnd();
      this.paymentObj.tenderInfo.routingNumber =
        this.addACHForm.get('routingNumber').value;
      this.paymentObj.tenderInfo.bankName =
        this.addACHForm.get('bankName').value;
      this.paymentObj.tenderInfo.accountNumber =
        this.addACHForm.get('accountNumber').value;

      this.paymentObj.SaveCustomer = this.addACHForm.get('saveForFuture').value
        ? true
        : false;
      let achFields = [
        'cardHolderName',
        'cardType',
        'cardNumber',
        'cardExpiry',
        'cvDataStatus',
        'cvv',
      ];
      achFields.forEach((item) => {
        delete this.paymentObj.tenderInfo[item];
      });
      this.paymentObj.billingcontact.address = null;
      if(this.selectedAccountType !== ''  && this.featureFlags['ach-classification-enforcement']){
        if(this.selectedAccountType == 'savings'){
          this.paymentObj.tenderInfo.accountType = 'savings'
        }else{
          this.paymentObj.tenderInfo.accountType = 'checking'
        }
      }else{
        this.paymentObj.tenderInfo.accountType = 'checking'
      }
      if(this.selectedBankType !== '' && this.featureFlags['ach-classification-enforcement']){
        if(this.selectedBankType === 'business'){
           this.paymentObj.transactionCode = 'CCD'
        }else{
          this.paymentObj.transactionCode = 'WEB'
        }
      }
    }
    this.paymentObj.tenderInfo.Amount =
      this.paymentInfoForm.get('Amount').value;
    this.paymentObj.AdditionalFields.invoicenumber =
      this.contactInfo.InvoiceNumber;
    this.paymentObj.AdditionalFields.other = this.contactInfo.Other;
    this.paymentObj.AdditionalFields.policynumber =
      this.contactInfo.PolicyNumber;
    this.paymentObj.AdditionalFields.accountnumber =
      this.contactInfo.AccountNumber;
    this.paymentObj.AdditionalFields.shoppercompanyname = this.contactInfo.Company;
    this.paymentObj.AdditionalFields.purposeofpayment =
      this.contactInfo.paymentPurpose;
  }

  callPaymentAPI(token, type) {
    if (type == 'ACH') {
      this.createPaymentObject(type);
    } else if (type == 'CARD') {
      this.createPaymentObject(type);
    }

    this.loader = true;
    let requestOptions;
    this.paymentObj.captchaToken = token;
    this.paymentObj.actionName = 'anytimePayment';
    requestOptions = this.paymentObj;

    this.initErrors();
    this.paymentInformationService.paymentAPI(requestOptions, this.uuidValue).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.renderer.removeClass(document.body, 'recaptcha');

        this.storageService.save(
          StorageType.session,
          'paymentsResponse',
          JSON.stringify(data)
        );
        if (
          data.transactionStatus == 'Authorized' || data.transactionStatus == "Success" ||
          data.transactionStatus == 'Posted'
        ) {
          let headerStatus = {
            contactInfo: 'completed',
            paymentInfo: 'completed',
            summaryInfo: 'active',
            privacyPolicy: 'inactive',
            termUse: 'inactive',
            paymentSummary: data,
          };
          this.updateStatus(headerStatus);
        } else {
          this.loader = false;
          if (
            data.transactionResult?.success == false &&
            data.transactionResult.errorMessage == null
          ) {
            data.transactionResult.errorMessage = Exception.exceptionMessage(
              data.transactionResult.additionResultData
            );
            this.correctError = true;
            this.displayErrorMessage = data.transactionResult.errorMessage;
            this.paymentErrors.transactionResult = data.transactionResult;
          } else {
            this.paymentErrors.transactionResult = data.transactionResult;
          }
          this.generateRandomUniqueID();
          this.paymentSubmitted = false;
          this.showError = true;
        }
      },
      error: (error) => {
        this.paymentSubmitted = false
        this.loader = false;
        this.generateRandomUniqueID();
        if (error?.error?.errors?.length > 0) {
          if (error.error != null) {
            this.paymentErrorDisplayFinal = [];
            for (let i = 0; i < error.error.errors.length; i++) {
              let paymentErrorDisplay = Exception.exceptionMessage(
                error.error.errors[i].message
              );
              this.paymentErrorDisplayFinal.push(paymentErrorDisplay);
            }
            this.paymentErrors.error = error?.error;
          } else {
            this.paymentErrors.error_message = error?.error?.message;
          }
        }
        else if (error?.status == 400) {
          this.paymentErrors.error = Exception.exceptionMessage(
            error
          );
        }
        else if (error?.status == 401) {
          this.error401 = true;
          if (
            this.settings.transactionSummary &&
            (this.settings.transactionSummary.email ||
              this.settings.transactionSummary.mobileNumber)
          ) {
            this.paymentErrors.error_message =
              MessageSetting.common.unauthorizedSupportInfoError;
          } else {
            this.paymentErrors.error_message =
              MessageSetting.common.UnauthorizedSupportError;
          }
        }
        else {
          this.paymentErrors.error =Exception.exceptionMessage(error);
        }
        this.showError = true;
      },
    });
  }

  getOriginalExpiryDate(expiryDate) {
    if (expiryDate.includes('/')) {
      return expiryDate.slice(0, 2) + expiryDate.slice(3, expiryDate.length);
    } else {
      return expiryDate;
    }
  }

  initErrors() {
    this.paymentErrors = {
      transactionResult: '',
      error: '',
    };
  }

  omit_cardHolderName_SupportedChars(event) {
    var k;
    k = event.charCode || event.keyCode; // Use charCode if available, otherwise fallback to keyCode

    // Allow alphabetic characters (uppercase and lowercase), spaces, commas, periods, hyphens, backticks, single quote
    var allowedChars = (k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 8 || k == 32 || k == 44 || k == 46 || k == 45 || k == 96 || k == 39;

    // Allow numeric characters but limit to 8 digits
    if (k >= 48 && k <= 57) {
        var inputField = event.target;
        var value = inputField.value;
        var digitCount = (value.match(/\d/g) || []).length;

        if (digitCount >= 8) {
            return false; // Prevent further numeric input if there are already 8 digits
        }
        return true;
    }

    return allowedChars;
  }

  // restrictDigits(event) {
  //   var inputField = event.target;
  //   var value = inputField.value;
  //   var digitCount = (value.match(/\d/g) || []).length;

  //   if (digitCount > 8) {
  //       // Trim the input value to have at most 8 digits
  //       var newValue = value.replace(/\d/g, (match, offset, string) => {
  //           // Replace digits with empty string if more than 8 digits
  //           return digitCount-- > 8 ? '' : match;
  //       });
  //       inputField.value = newValue.trim();
  //   }
  // }
  restrictDigits(event) {
    this.visitedFlag = true;
    var inputField = event?.target;
    var value = inputField?.value;
    var digitCount = 0;
    var newValue = '';
    for (var i = 0; i < value.length; i++) {
        var char = value.charAt(i);

        if (/\d/.test(char)) {
            if (digitCount < 8) {
                newValue += char;
                digitCount++;
            }
        } else {
            newValue += char;
        }
    }
    inputField.value = newValue

}

  omit_accountHolderName_SupportedChars(event) {
    var k;
    k = event.charCode || event.keyCode; // Use charCode if available, otherwise fallback to keyCode

    // Allow alphabetic characters (uppercase and lowercase), numbers, spaces, commas, periods, hyphens, backticks and single quote
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 44 || k == 46 || k == 45 || k == 96 || k == 39);
  }

  omit_special_char_without_number(event) {
    var k;
    k = event.charCode; // or event.keyCode (both can be used)

    // Allow alphabetic characters (uppercase and lowercase), backspace, space, and numbers
    return (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 8 || k == 32;
  }

  omit_numeric_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 45 || k == 44 || k == 46 || k == 39 || k == 96;
  }

  omit_alpha_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57) || k == 8;
  }

  mobile_aipha_special_char(event){
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57) || k == 8 || k == 32;
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57);
  }

  clearSpaces(event){
    var cardNumberInputField = event?.target;
    var cardNumberValue = cardNumberInputField?.value;
    var cardNumberNewValue = cardNumberValue.replace(/\s+/g, '');
    // this.cardNumberLength = this.cardNumberLength;
    var cardNumberNewestValue = cardNumberNewValue.slice(0,16);
    cardNumberInputField.value = cardNumberNewestValue;
    this.addCardForm.get('CardNumber').value = cardNumberNewestValue;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  resetVerifyAccountNumber() {
    if (
      this.addACHForm.get('verifyAccountNumber').status != 'VALID' &&
      this.addACHForm.get('verifyAccountNumber')
    ) {
      this.searchValue = '';
    }
  }

  setDefaultImage() {
    this.cardImageName = '../../../../assets/images/BlankCard.png';
    this.defaultAllCards = false;
  }

  validatePostalCode(event) {
    let countryCode = this.addCardForm.controls.address.controls.country.value;
    if(countryCode !== '1' && countryCode !== '2'){
      this.postalCodeMask = "";
      this.postalCodeLength = 12;
      const countryInfo = this.lookupCountryList.find(
        country => country.countryId == countryCode
    );
    if (countryInfo && countryInfo.zipcodeValidation) {
        const countryRegex = new RegExp(`^${countryInfo.zipcodeValidation}$`);
        if (!countryRegex.test(event.target.value)) {
          this.addCardForm.controls.address.controls.postalCode?.addValidators([
              Validators.pattern(`^${countryInfo.zipcodeValidation}$`),
            ]);
            this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
        } else {
          this.addCardForm.controls.address.controls.postalCode?.clearValidators();
          this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
        }
    } else {
      this.addCardForm.controls.address.controls.postalCode?.clearValidators();
      this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
    }
    }
    if (this.addCardForm.controls.address.controls.country.value == '1') {
      this.postalCodeMask = '00000-0000';
      if (
        event.target?.value.indexOf('-') != -1 &&
        event.target?.value.length != 10
      ) {
        this.postalCodeMinLength = 9;
        this.addCardForm.controls.address.controls.postalCode?.addValidators([
          Validators.minLength(10),
        ]);
        this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
        this.config.postalCode.minlength.min = this.postalCodeMinLength;
        this.validator = new Validator(this.config);
        this.validator.validateAllFormFields(this.addCardForm);
        this.addCardErrors = this.validator.validate(this.addCardForm);
        this.checkCardNumberUndefined();
        return;
      } else if (
        event.target?.value.indexOf('-') == -1 &&
        event.target?.value.length < 5
      ) {
        this.postalCodeMinLength = 5;
        this.addCardForm.controls.address.controls.postalCode?.addValidators([
          Validators.minLength(5),
        ]);
        this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
        this.config.postalCode.minlength.min = this.postalCodeMinLength;
        this.validator = new Validator(this.config);
        this.validator.validateAllFormFields(this.addCardForm);
        this.addCardErrors = this.validator.validate(this.addCardForm);
        this.checkCardNumberUndefined();
        return;
      } else {
        this.addCardForm.controls.address.controls.postalCode?.clearValidators();
        this.addCardForm.controls.address.controls.postalCode?.setValidators([
          Validators.required,
        ]);
        this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
      }
    } else if (
      this.addCardForm.controls.address.controls.country.value == '2'
    ) {
      this.postalCodeMask = 'S0S 0S0';
      this.postalCodeLength = 7;
      this.postalCodeMinLength = 6;
      this.addCardForm.controls.address.controls.postalCode?.addValidators([
        // Validators.required,
        Validators.minLength(7),
      ]);
      this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
      this.config.postalCode.minlength.min = this.postalCodeMinLength;
      this.validator = new Validator(this.config);
      this.validator.validateAllFormFields(this.addCardForm);
      this.addCardErrors = this.validator.validate(this.addCardForm);
      this.checkCardNumberUndefined();
    } else {
      this.postalCodeMask = '';
    }

    if (event.target?.value.length == 0 && (countryCode == 1 || countryCode == 2)) {
      this.addCardForm.controls.address.controls.postalCode?.clearValidators();
      this.addCardForm.controls.address.controls.postalCode?.setValidators([
        Validators.required,
      ]);
      this.addCardForm.controls.address.controls.postalCode?.updateValueAndValidity();
    }
  }
  pasChecked() {
    if (this.show_tab == 'ACH') {
      this.validator = new Validator(this.config);
      this.validator.validateAllFormFields(this.addACHForm);
      this.addACHFormErrors = this.validator.validate(this.addACHForm);
    } else {
      this.validator = new Validator(this.config);
      this.validator.validateAllFormFields(this.addCardForm);
      this.addCardErrors = this.validator.validate(this.addCardForm);
    }
  }

  pointGen(pattern, num) {
    return Array.apply(null, Array(num))
      .map(() => pattern)
      .join('');
  }

  handlePasswordChange(event: any) {
    this.isTimeoutRunning = true;
    // insert cursor location
    const index = event.target.selectionStart;
    const inputText = event.target.value;

    // increase length of input's value
    const addedTextLength = inputText.length - this.unmaskedPassword.length;

    // increase text
    if (addedTextLength > 0) {
      const newStr = inputText.slice(index - addedTextLength, index);
      this.unmaskedPassword =
        this.unmaskedPassword.slice(0, index - addedTextLength) +
        newStr +
        this.unmaskedPassword.slice(index - addedTextLength);
    } else if (addedTextLength < 0) {
      this.unmaskedPassword =
        this.unmaskedPassword.slice(0, index) +
        this.unmaskedPassword.slice(index - addedTextLength);
    }

    // to check whether user stop typing
    if (inputText.length === 0) {
      this.password = '';
    } else {
      this.password =
        this.pointGen(this.pattern, inputText.length - 1) +
        inputText.charAt(inputText.length - 1);
    }
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      this.isTimeoutRunning = false;
      if (event.keyCode != 13) {
        $this.maskAccNumber(event);
      }
    }, 2000);
  }

  maskAccNumber(event: any) {
    this.password = this.pointGen(this.pattern, this.password.length);
    this.isTimeoutRunning = false;
  }

  changeAccNumberText() {
    if (this.showPassword) {
      this.password = this.unmaskedPassword;
    } else {
      this.password = this.pointGen(this.pattern, this.password.length);
    }
  }

  focusInputSec(event){
    const inputSecurity = document?.getElementById('inputSecurity') as HTMLInputElement;
    inputSecurity?.removeAttribute('readonly');
  }

  blurInputSec(event){
    const inputSecurity = document?.getElementById('inputSecurity') as HTMLInputElement;
    if(inputSecurity?.value?.length==0){
      inputSecurity?.setAttribute('readonly', 'true');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.forms.forEach(form => {
        const inputFields = form.nativeElement.querySelectorAll('input');
        inputFields.forEach((element: HTMLInputElement) => {
          element.setAttribute('autocomplete', 'off');
        });
      });
      const inputSecurity = document?.getElementById('inputSecurity') as HTMLInputElement;
      inputSecurity?.setAttribute('readonly', 'true');
      // Manually detect changes after a slight delay
      this.cdr.detectChanges();
    }, 700);
  }
  stateChange(input){
    this.selectedState = input
    this.addCardForm.controls.address.controls.state.value = input?.abbreviation
    this.showStateDropdown = false
  }
  toggleDropDown(event : Event,fieldname){
    event.stopPropagation();
    switch (fieldname) {
      case 'country':
        this.showDropdown = !this.showDropdown;
        this.showStateDropdown = false
        break;
      case 'state':
        this.showDropdown = false;
        this.showStateDropdown = !this.showStateDropdown
      break;
    }
  }
  handleMouseDown(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    this.showDropdown = false
    this.showStateDropdown = false
  }
  getPaymentAcceptanceObject(response) {
    // Initialize the object to store amounts
    this.paymentAcceptanceLimit = {};
    // Variable to keep track of the highest amount
     this.highestPaymentAcceptanceLimit = 0;
     this.lowestPaymentAcceptanceLimit = Infinity;

    // Iterate through the applicableTo array
    response?.applicableTo?.forEach(item => {
        item?.paymentType?.forEach(type => {
            let uppercaseType = type.toUpperCase();

            // Add or accumulate the amount for the current payment type
            if (this.paymentAcceptanceLimit.hasOwnProperty(uppercaseType)) {
                this.paymentAcceptanceLimit[uppercaseType] += item.amount;
            } else {
                this.paymentAcceptanceLimit[uppercaseType] = item.amount;
            }

            // Update highest amount if the current amount is greater
            if (this.paymentAcceptanceLimit[uppercaseType] > this.highestPaymentAcceptanceLimit) {
              this.highestPaymentAcceptanceLimit = this.paymentAcceptanceLimit[uppercaseType];
            }
            if (this.paymentAcceptanceLimit[uppercaseType] < this.lowestPaymentAcceptanceLimit) {
              this.lowestPaymentAcceptanceLimit = this.paymentAcceptanceLimit[uppercaseType];
            }
        });
    });

    // Format amounts to two decimal places
    //  for (const key in this.paymentAcceptanceLimit) {
    //      if (Object.prototype.hasOwnProperty.call(this.paymentAcceptanceLimit, key)) {
    //          this.paymentAcceptanceLimit[key] = parseFloat(this.paymentAcceptanceLimit[key].toFixed(2));
    //      }
    //  }
}


  // Helper functions to set or remove d-none on an element
setHidden (element) {
  const classes = element?.classList;
  if(!classes?.contains('d-none')) {
      classes?.add('d-none');
  }
}

 setVisible (element) {
  const classes = element?.classList;
  if(classes?.contains('d-none')) {
      classes?.remove('d-none');
  }
}
setActiveClass (element) {
  const classes = element?.classList;
  if(!classes?.contains('active')) {
      classes?.add('active');
  }
}

setInactiveClass (element) {
  const classes = element?.classList;
  if(classes?.contains('active')) {
      classes?.remove('active');
  }
}
setCardStatus(value, input) {
let section = input.closest('.input-section');
const warningBox = document.querySelector('.warning-box');
const warningIcon = document.querySelector('#warningBoxIcon');
const errorIcon = document.querySelector('#errorBoxIcon');
const infoIcon = document.querySelector('#infoBoxIcon');
let warningBoxText = document.querySelector('#warningText') as HTMLElement;
const achBox = document.getElementById('achBox');
const cardBox = document.getElementById('cardBox');
const nextButton = document.getElementById('next-button');
  if((Number(value) < 10 && cardBox) || value?.trim() == ''){
    cardBox?.classList?.add('debit-disabled');
    cardBox?.classList?.add('credit-disabled');
    cardBox?.classList?.add('disabled');
    this.setInactiveClass(cardBox)
    this.paymentTypeChoice.CARD = false
  }else if (Number(value) && Number(value) >= 10 && cardBox){
    cardBox?.classList?.remove('debit-disabled');
    cardBox?.classList?.remove('credit-disabled');
    cardBox?.classList?.remove('disabled');
    if ((this.allowTransctioList.CreditCard || this.allowTransctioList.DebitCard))
{
  this.setActiveClass(cardBox);
  this.setInactiveClass(achBox)
  this.paymentTypeChoice.ACH = false;
  // this.paymentTypeChoice.CARD = true;

}  }
  if(value?.trim() !== '' && achBox && Number(value)){
    achBox?.classList?.remove('disabled');
    if(Number(value) > this.paymentAcceptanceLimit.CREDITCARD && Number(value) > this.paymentAcceptanceLimit.DEBITCARD && this.featureFlags['payment-amount-rate-limit']){
      this.setActiveClass(achBox);
    }
    if (((!this.allowTransctioList.CreditCard && !this.allowTransctioList.DebitCard) || !this.paymentTypeChoice.CARD) || Number(value) < 10)
      {
        if(this.allowTransctioList.ACH )this.setActiveClass(achBox);
      }
  }else{
    achBox?.classList?.add('disabled');
    this.setInactiveClass(achBox);
  }
  achBox?.removeAttribute('ad-tooltip');
  achBox?.removeAttribute('data-body');
  cardBox?.removeAttribute('ad-tooltip');
  cardBox?.removeAttribute('data-body');
  Object.keys(this.paymentAcceptanceLimit).map(key => {
      if(+value > this.paymentAcceptanceLimit[key]) {
          switch(key?.toLowerCase()) {
              case 'ach':
                  this.ach = false;
                  this.achPaymmentLimit = this.paymentAcceptanceLimit[key]
                  break;
              case 'creditcard':
                  this.credit = false;
                  this.creditPaymentLimit = this.paymentAcceptanceLimit[key]
                  break;
              case 'debitcard':
                  this.debit = false;
                  this.debitPaymentLimit = this.paymentAcceptanceLimit[key]
                  break;
              default:
                  break;
          }
      }
  })
  //after looping, check the cards value to see if one or both cards are disabled
  if(this.ach === false && this.credit === false && this.debit === false && value && (this.allowTransctioList.CreditCard || this.allowTransctioList.DebitCard) && this.allowTransctioList.ACH && this.featureFlags['payment-amount-rate-limit']) {
      this.setVisible(warningBox);
      this.setVisible(errorIcon);
      this.setHidden(warningIcon);
      this.setHidden(infoIcon);

      this.setInactiveClass(achBox);
      this.setInactiveClass(cardBox);

      achBox.classList.add('disabled');
      cardBox.classList.add('disabled');
      this.highestPaymentAcceptanceLimit = Math.max(this.achPaymmentLimit, this.creditPaymentLimit, this.debitPaymentLimit)
      warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
  } else if(this.credit == false && this.debit == false && value && this.allowTransctioList.CreditCard && this.allowTransctioList.DebitCard && this.featureFlags['payment-amount-rate-limit']) {
      cardBox.classList.add('disabled');
      this.setInactiveClass(cardBox);

      this.setVisible(warningBox);
      this.setHidden(errorIcon);
      this.setVisible(warningIcon);
      this.setHidden(infoIcon);

      warningBoxText.innerText = `Input amount exceeds debit payment limit of ${this.numberPipe.transform(this.debitPaymentLimit || 0, '1.0-2')} and credit payment limit of ${this.numberPipe.transform(this.creditPaymentLimit || 0, '1.0-2')}.`;
      if(!this.allowTransctioList.ACH){
        this.highestPaymentAcceptanceLimit = Math.max(this.debitPaymentLimit, this.creditPaymentLimit);
        warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
        section?.classList?.remove('warning');
        if(!section?.classList?.contains('error')) section?.classList?.add('error');
        this.setVisible(warningBox);
        this.setVisible(errorIcon);
        this.setHidden(warningIcon);
        this.setHidden(infoIcon);
      }
  }else if(this.ach == false && this.debit == false && value && this.allowTransctioList.ACH && this.allowTransctioList.DebitCard && this.featureFlags['payment-amount-rate-limit']) {
    achBox.classList.add('disabled');
    this.setInactiveClass(achBox);

    this.setVisible(warningBox);
    this.setHidden(errorIcon);
    this.setVisible(warningIcon);
    this.setHidden(infoIcon);

    warningBoxText.innerText = `Input amount exceeds ACH payment limit of ${this.numberPipe.transform(this.achPaymmentLimit || 0, '1.0-2')} and debit payment limit of ${this.numberPipe.transform(this.debitPaymentLimit || 0, '1.0-2')}.`;
    if(!this.allowTransctioList.CreditCard){
      this.highestPaymentAcceptanceLimit = Math.max(this.debitPaymentLimit, this.achPaymmentLimit);
      warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
      section?.classList?.remove('warning');
      if(!section?.classList?.contains('error')) section?.classList?.add('error');
      this.setVisible(warningBox);
      this.setVisible(errorIcon);
      this.setHidden(warningIcon);
      this.setHidden(infoIcon);
    }
}else if(this.credit == false && this.ach == false && value && this.allowTransctioList.CreditCard && this.allowTransctioList.ACH && this.featureFlags['payment-amount-rate-limit']) {
  achBox.classList.add('disabled');
  this.setInactiveClass(achBox);

  this.setVisible(warningBox);
  this.setHidden(errorIcon);
  this.setVisible(warningIcon);
  this.setHidden(infoIcon);

  warningBoxText.innerText = `Input amount exceeds ACH payment limit of ${this.numberPipe.transform(this.achPaymmentLimit || 0, '1.0-2')} and credit payment limit of ${this.numberPipe.transform(this.creditPaymentLimit || 0, '1.0-2')}.`;
  if(!this.allowTransctioList.DebitCard){
    this.highestPaymentAcceptanceLimit = Math.max(this.achPaymmentLimit, this.creditPaymentLimit);
    warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
    section?.classList?.remove('warning');
    if(!section?.classList?.contains('error')) section?.classList?.add('error');
    this.setVisible(warningBox);
    this.setVisible(errorIcon);
    this.setHidden(warningIcon);
    this.setHidden(infoIcon);
  }
}else if(this.ach == false && value && this.allowTransctioList.ACH && this.featureFlags['payment-amount-rate-limit']) {
      achBox.classList.add('disabled');
      this.setInactiveClass(achBox);
      this.setVisible(warningBox);
      this.setHidden(errorIcon);
      this.setVisible(warningIcon);
      this.setHidden(infoIcon);

      warningBoxText.innerText = `Input amount exceeds ACH payment limit of ${this.numberPipe.transform(this.achPaymmentLimit || 0, '1.0-2')}.`;
      if(!this.allowTransctioList.CreditCard && !this.allowTransctioList.DebitCard){
        this.highestPaymentAcceptanceLimit = this.achPaymmentLimit;
        warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
        section?.classList?.remove('warning');
        if(!section?.classList?.contains('error')) section?.classList?.add('error');
        this.setVisible(warningBox);
        this.setVisible(errorIcon);
        this.setHidden(warningIcon);
        this.setHidden(infoIcon);
      }
  } else if(this.debit == false && value && this.allowTransctioList.DebitCard && this.featureFlags['payment-amount-rate-limit']) {
      this.setVisible(warningBox);
      this.setHidden(errorIcon);
      this.setVisible(warningIcon);
      this.setHidden(infoIcon);
      if(!this.allowTransctioList.CreditCard){
        this.setInactiveClass(cardBox)
        cardBox.classList.add('disabled');
        this.setActiveClass(achBox);
      }

      warningBoxText.innerText = `Input amount exceeds debit payment limit of ${this.numberPipe.transform(this.debitPaymentLimit || 0, '1.0-2')}.`;
      if(!this.allowTransctioList.CreditCard && !this.allowTransctioList.ACH){
        this.highestPaymentAcceptanceLimit = this.debitPaymentLimit;
        warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
        section?.classList?.remove('warning');
        if(!section?.classList?.contains('error')) section?.classList?.add('error');
        this.setVisible(warningBox);
        this.setVisible(errorIcon);
        this.setHidden(warningIcon);
        this.setHidden(infoIcon);
      }
  } else if(this.credit == false && value && this.allowTransctioList.CreditCard && this.featureFlags['payment-amount-rate-limit']) {
      this.setVisible(warningBox);
      this.setHidden(errorIcon);
      this.setVisible(warningIcon);
      this.setHidden(infoIcon);
      if(!this.allowTransctioList.DebitCard){
        this.setInactiveClass(cardBox)
        cardBox.classList.add('disabled');
        this.setActiveClass(achBox);
      }
      warningBoxText.innerText = `Input amount exceeds credit payment limit of ${this.numberPipe.transform(this.creditPaymentLimit || 0, '1.0-2')}.`;
      if(!this.allowTransctioList.DebitCard && !this.allowTransctioList.ACH){
        this.highestPaymentAcceptanceLimit = this.creditPaymentLimit;
        warningBoxText.innerText = `The input amount exceeds all payment limits. Enter an amount below ${this.numberPipe.transform(this.highestPaymentAcceptanceLimit || 0, '1.0-2')} to proceed.`;
        section?.classList?.remove('warning');
        if(!section?.classList?.contains('error')) section?.classList?.add('error');
        this.setVisible(warningBox);
        this.setVisible(errorIcon);
        this.setHidden(warningIcon);
        this.setHidden(infoIcon);
      }
  } else if (value?.trim() !== '') {
      this.setHidden(warningBox);
      section?.classList?.remove('error');
  }else if(value?.trim() == '' || !Number(value)){
    this.setVisible(warningBox);
    if(this.paymentInfoForm?.value?.Amount?.length == 10 || (this.paymentInfoForm?.value?.Amount?.length > 0 && this.paymentInfoForm.invalid) ||  (+this.paymentInfoForm?.value?.Amount === 0 && this.paymentInfoForm?.value?.Amount !== '')){
      warningBoxText.innerText= 'Please enter a valid amount'
      achBox?.classList?.add('disabled')
      cardBox?.classList?.add('disabled');
      if((!section?.classList?.contains('error'))) section?.classList?.add('error');
      setTimeout(() => {
      achBox?.classList?.add('disabled')
      cardBox?.classList?.add('debit-disabled');
      cardBox?.classList?.add('credit-disabled');
      cardBox?.classList?.add('disabled');
      this.setInactiveClass(cardBox)
      this.setInactiveClass(achBox)
      this.setVisible(warningBox);
      this.setVisible(errorIcon);
      this.setHidden(warningIcon);
      this.setHidden(infoIcon);
      }, 0);
    }else{
      warningBoxText.innerText = 'Please enter an amount to proceed.'
      this.setVisible(warningBox);
      this.setHidden(errorIcon);
      this.setVisible(warningIcon);
      this.setHidden(infoIcon);
      section?.classList?.remove('error');
    }
  }
  //if all are false, disable next, if atleast one is not false, make that the active one and enable next
  if((achBox?.classList?.contains('active') || cardBox?.classList?.contains('active'))) {
      nextButton?.classList?.remove('disabled');
  } else if(!achBox?.classList?.contains('active') && !cardBox?.classList?.contains('active')) {
      nextButton?.classList?.add('disabled');
  }

}

checkAmountThreshold(input) {
  //check the threshold objects keys to see if the value is exceeding any of them then show the associated error or warning icon with tooltip
  let temp = 0;
  Object.keys(this.paymentAcceptanceLimit).map(key => {
      if(input.value > this.paymentAcceptanceLimit[key]) {
          temp++;
      }
  })
  let section = input.closest('.input-section');
  if(temp > 0 && temp < 3) {
      //set the section to 'warning'
      section?.classList?.remove('error');
      if(!section?.classList?.contains('warning') && ((this.allowTransctioList.ACH && this.allowTransctioList.CreditCard) || (this.allowTransctioList.ACH && this.allowTransctioList.DebitCard) || (this.allowTransctioList.CreditCard && this.allowTransctioList.DebitCard)) && this.featureFlags['payment-amount-rate-limit'])section?.classList?.add('warning');
  } else if(temp == 3 ) {
      //set the section to 'error'
      section?.classList?.remove('warning');
      if((!section?.classList?.contains('error') && this.featureFlags['payment-amount-rate-limit'])) section?.classList?.add('error');
  } else {
      //set the section to 'warning'
      section?.classList?.remove('error');
  }
}
  setBankType(type: string) {
    this.selectedBankType = type;
    this.BankTypeSelected = true
  }

  // Method to set the selected account type
  setAccountType(type: string) {
    this.selectedAccountType = type;
    this.AccTypeSelected = true
  }
  }

